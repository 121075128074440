import { useState } from 'react';
import { Marker, Polyline, useMap, useMapEvents } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';

import { PositionList } from '@/tmp';
import { ArrowMarker } from './ArrowMarker';
import { calcSections } from './sections';
import { PositionMinTime } from '@/tmp/Route';

// FIXME ezek az ertekek miert jok ?
const startFlagDefault = L.icon({
  iconUrl: '/svg/startFlag.svg',
  iconSize: [45, 45],
  iconAnchor: [10, 15 + 3 * 8],
});

const stopFlagDefault = L.icon({
  iconUrl: '/svg/stopFlag.svg',
  iconSize: [45, 45],
  iconAnchor: [10, 15 + 3 * 8],
});

function DisplayRoute({ route, startFlag, stopFlag }: { route: PositionList; startFlag: L.Icon; stopFlag: L.Icon }) {
  const positionsFiltered: PositionMinTime[] = route.positions?.filter((p) => p !== undefined) ?? [];
  const positions: LatLngExpression[] = positionsFiltered.map((p) => [p.latitude, p.longitude]);
  const startPosition: LatLngExpression = positions[0];
  const stopPosition: LatLngExpression = positions[positions.length - 1];

  const sections = calcSections(positionsFiltered);

  return (
    <>
      <Polyline positions={positions} stroke={true} weight={8} color={'#222222'} opacity={0.75} />
      <Polyline positions={positions} stroke={true} weight={6} color={'#FF1414'} opacity={1} />
      {sections.map((s, i) => (
        <ArrowMarker position={s.midPoint} angle={s.angle} key={i} />
      ))}
      <Marker position={startPosition} icon={startFlag} />
      <Marker position={stopPosition} icon={stopFlag} />
    </>
  );
}

export function Route({ routes }: { routes: PositionList[] | undefined }) {
  const [startFlag, setStartFlag] = useState<L.Icon<L.IconOptions>>(startFlagDefault);
  const [stopFlag, setStopFlag] = useState<L.Icon<L.IconOptions>>(stopFlagDefault);
  const map = useMap();

  useMapEvents({
    zoomend: () => {
      if (map?.getZoom()) {
        const zoom = map.getZoom();
        setStartFlag(() => {
          const newIcon = L.icon({
            iconUrl: '/svg/startFlag.svg',
            iconSize: [20 + 3 * zoom, 20 + 3 * zoom],
            iconAnchor: [15, 15 + 3 * zoom],
          });
          return newIcon;
        });
        setStopFlag(() => {
          const newIcon = L.icon({
            iconUrl: '/svg/stopFlag.svg',
            iconSize: [20 + 3 * zoom, 20 + 3 * zoom],
            iconAnchor: [15, 15 + 3 * zoom],
          });
          return newIcon;
        });
      }
    },
  });

  if (!routes || !routes.length) return null;

  return (
    <>
      {routes.map((r) => {
        let key: number;
        if (r.positions && r.positions.length) {
          key = r.positions[0].timeMicros;
        } else if (r.events && r.events.length) {
          key = r.events[0].eventTimeMicros;
        } else if (r.markers && r.markers.length) {
          key = r.markers[0].position.latitude + r.markers[0].position.longitude;
        } else {
          key = Math.floor(Math.random() * 10000); // FIXME
        }
        return <DisplayRoute key={key} route={r} startFlag={startFlag} stopFlag={stopFlag} />;
      })}
    </>
  );
}

/* FIXME ez meg kellhet...

    const ARROW_COUNT = 7;
    if (positions.length === 0) return null;

    const sectionsWithVisibleMidPoint = sections.filter( s => mapBounds.contains(s.midPoint))
    sectionsWithVisibleMidPoint.forEach( s => s.arrowRelevance = null)
    const proposedArrowCount = sectionsWithVisibleMidPoint.length >= ARROW_COUNT ? ARROW_COUNT : sectionsWithVisibleMidPoint.length
    const visibleRouteLength = sectionsWithVisibleMidPoint.reduce((partialSum, s) => partialSum + s.length, 0);
    const optimalClusterLength = visibleRouteLength / (proposedArrowCount + 1)

    let sectionStartDistance = 0;
    for (let i = 0; i < sectionsWithVisibleMidPoint.length; i++) {
        const s = sectionsWithVisibleMidPoint[i]
        if ((s.endPointDistanceFromStart - sectionStartDistance) >= optimalClusterLength) {
            sectionStartDistance = s.endPointDistanceFromStart
            s.arrowRelevance = 100
        }
    }

    const sectionsWithArrow = [...sectionsWithVisibleMidPoint.filter( s => s.arrowRelevance > 1)]
*/
