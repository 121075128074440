import { ErrorInfo, RefObject, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient } from '@tanstack/react-query';

import { Message, SyncAlt, MoreHoriz, LocalGasStation, GpsFixed } from '@mui/icons-material';
import { Badge } from '@mui/material';

import { PopupType } from './types';
import type { MessagesPopup, More, TrackingPopup, RoutesPopup, RefuelsPopup } from './components';
import Error from '@/components/common/Error';

// eslint-disable-next-line
const TrackingPopupLazy = lazy(() => import('./components/tracking/TrackingPopup')) as typeof TrackingPopup;
// eslint-disable-next-line
const RoutesPopupLazy = lazy(() => import('./components/routes/RoutesPopup')) as typeof RoutesPopup;
// eslint-disable-next-line
const MessagesPopupLazy = lazy(() => import('./components/messages/MessagesPopup')) as typeof MessagesPopup;
// eslint-disable-next-line
const RefuelsPopupLazy = lazy(() => import('./components/refuels/RefuelsPopup')) as typeof RefuelsPopup;
// eslint-disable-next-line
const MoreLazy = lazy(() => import('./components/More')) as typeof More;

const newMessageCount = 0;

export function popupDefs({
  close,
  logout,
  ref,
  client,
}: {
  close: () => void;
  logout: () => void;
  ref: RefObject<HTMLAnchorElement | null>;
  client: QueryClient;
}): PopupType[] {
  function logError(error: Error, info: ErrorInfo) {
    console.log(error.message);
    console.log(info);
  }

  // FIXME ez igy nem lazy loading ?
  return [
    {
      title: 'mainMenu.tracking',
      icon: <GpsFixed fontSize="small" />,
      element: <TrackingPopupLazy close={close} data-qa-element-id="tracking-popup" />,
      ref: undefined,
      showToken: true,
    },
    {
      title: 'mainMenu.routes',
      icon: <SyncAlt fontSize="small" />,
      element: (
        <ErrorBoundary FallbackComponent={Error} onError={logError} onReset={() => client.clear()}>
          <RoutesPopupLazy close={close} data-qa-element-id="routes-popup" />
        </ErrorBoundary>
      ),
      ref: undefined,
      showToken: false,
    },
    {
      title: 'mainMenu.messages',
      icon:
        newMessageCount > 0 ? (
          <Badge color="secondary" variant="dot">
            <Message fontSize="small" />
          </Badge>
        ) : (
          <Message fontSize="small" />
        ),
      // FIXME ne a react=query teljes cache-t torolje ? csak a messages ?
      element: (
        <ErrorBoundary FallbackComponent={Error} onError={logError} onReset={() => client.clear()}>
          <MessagesPopupLazy close={close} data-qa-element-id="messages-popup" />
        </ErrorBoundary>
      ),
      ref: undefined,
      showToken: false,
    },
    {
      title: 'mainMenu.refuels',
      icon: <LocalGasStation fontSize="small" />,
      element: <RefuelsPopupLazy close={close} data-qa-element-id="refuels-popup" />,
      ref: undefined,
      showToken: false,
    },
    {
      title: 'mainMenu.more',
      icon: <MoreHoriz fontSize="small" />,
      element: <MoreLazy close={close} ref={ref} logout={logout} data-qa-element-id="more-menu-list" />,
      ref,
      showToken: true,
    },
  ];
}
