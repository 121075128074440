import { lazy } from 'react';
import { Box, Container } from '@mui/material';

import { HomeBottomNav } from './HomeBottomNav';

import GlobalStates from './GlobalStates';
import Popups from './Popups';
import UserMessages from './UserMessages';
import { RoutesContext } from '@/contexts/RoutesCtx';
import { MessagesContext } from '@/contexts/MessagesCtx';

import './Home.css';

// TODO itt valamiert nem megy a tipusossag mint a tobbi lazy-nel...
const MapLazy = lazy(() => import('@/components/map/Map'));

export default function Home({ logout }: { logout: () => void }) {
  return (
    <GlobalStates>
      <RoutesContext>
        <MessagesContext>
          <Popups>
            <>
              <UserMessages />
              <Box className="home">
                <Container component="main" className="home-main" maxWidth={false}>
                  <MapLazy />
                </Container>
                <HomeBottomNav logout={logout} />
              </Box>
            </>
          </Popups>
        </MessagesContext>
      </RoutesContext>
    </GlobalStates>
  );
}
