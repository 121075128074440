import L from 'leaflet';
import { PositionMin, PositionMinTime } from '@/tmp/Route';

type Section = {
  angle: number;
  length: number;
  endPointDistanceFromStart: number;
  midPoint: PositionMin;
};

function midPoint(p1: PositionMinTime, p2: PositionMinTime): PositionMin {
  const latitude = (p1.latitude + p2.latitude) / 2;
  const longitude = (p1.longitude + p2.longitude) / 2;
  return { latitude, longitude } satisfies PositionMin;
}

function calcAngle(p1: PositionMinTime, p2: PositionMinTime) {
  const dy = p2.latitude - p1.latitude;
  const dx = Math.cos((Math.PI / 180) * p1.latitude) * (p2.longitude - p1.longitude);
  const ang = (Math.atan2(dy, dx) / Math.PI) * 180 * -1;
  return ang;
}

export function calcSections(pos: PositionMinTime[]): Section[] {
  const sections: Section[] = [];
  let totalRouteLength = 0;
  const step = Math.ceil(pos.length / 10);
  for (let i = step; i < pos.length; i += step) {
    // FIXME
    const pa1 = pos[i - 1];
    const pa2 = pos[i];

    const latLng1 = new L.LatLng(pa1.latitude, pa1.longitude);
    const latLng2 = new L.LatLng(pa2.latitude, pa2.longitude);
    const distance = latLng1.distanceTo(latLng2);

    const section = {
      angle: calcAngle(pa1, pa2),
      length: distance,
      endPointDistanceFromStart: totalRouteLength + distance,
      midPoint: midPoint(pa1, pa2),
    } satisfies Section;

    totalRouteLength += distance;
    sections.push(section);
  }
  return sections;
}
