import L from 'leaflet';
import { Marker } from 'react-leaflet';

import { PositionMin } from '@/tmp/Route';

import './ArrowMarker.css';

const arrow = `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <g fill="white" strokeWidth="1" strokeOpacity="0.5" stroke="black">
      <path d="M 21 12 L 12 18 L 12 15 L 3 15 L 3 9 L 12 9 L 12 6 L 21 12"></path>
    </g>
  </svg>
`;

export function ArrowMarker({ position, angle }: { position: PositionMin; angle: number }) {
  const htmlString = `
    <div style="display: flex; transform: rotate(${angle.toFixed(0)}deg)">
      ${arrow}
    </div>
  `;

  const htmlIcon = new L.DivIcon({
    html: htmlString,
    iconSize: [24, 24],
    className: 'arrow-marker-no-border-background',
    // popupAnchor: [12, 12], //NOTE: Very important to set, cannot be null, otherwise the leaflet library will crash! I did spend hours to find this bug ;)
  });

  // FIXME
  return <Marker icon={htmlIcon} position={{ lat: position.latitude, lng: position.longitude }} />;
}
