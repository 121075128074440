import { ReactElement, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';

import { ctxNotification } from '@/contexts/NotificationCtx';
import TrackingResponseHandler from './components/tracking/TrackingResponseHandler';

type ServerError = {
  localizedMessage: string;
  message: string;
};

export function HandleErrors({ children, logout }: { children: ReactElement; logout: () => void }) {
  const { t } = useTranslation();
  const ctxNotify = useContext(ctxNotification);
  const trackingResponseHandler = new TrackingResponseHandler();

  const handleServerSideMessage = useCallback(
    (error: AxiosError) => {
      const errorData = error.response?.data as ServerError;
      const localizedMessage = errorData?.localizedMessage;
      if (!localizedMessage) {
        return false;
      }
      ctxNotify?.showNotification('error', localizedMessage);
      const errorMessage = errorData.message;
      if (errorMessage) {
        console.warn(errorMessage);
      }
      return true;
    },
    [ctxNotify]
  );

  const handleUnknownError = useCallback(
    (error: AxiosError) => {
      let message = '';
      switch (error.response?.status) {
        case 403:
          message = t('error.api.err_forbidden');
          break;
        case 503:
          message = t('error.api.err_network');
          break;
        case 500:
        default:
          message = t('error.api.unknown');
      }
      ctxNotify?.showNotification('error', message);
      return Promise.reject(error);
    },
    [t, ctxNotify]
  );

  const handleTrackingError = useCallback(
    (error: AxiosError) => {
      const isTrackingUrl = error.config?.url?.includes('positions/tracking');
      if (!isTrackingUrl) {
        return false;
      }
      if (!error.response) {
        // errors without response like ERR_INTERNET_DISCONNECTED
        return true;
      }
      const status = error.response.status;
      if (status >= 400 && status < 500) {
        return false;
      }
      const errorHandled = trackingResponseHandler.handleFailedRequest();
      if (!errorHandled) {
        ctxNotify?.showNotification('error', t('error.api.failed_tracking'));
      }
      return true;
    },
    [t, ctxNotify]
  );

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const headerLanguage = localStorage.getItem('language') || 'hu';
        config.headers['Accept-language'] = headerLanguage;
        return config;
      },
      (error) => {
        // ez miert van itt is es a response-ban is ??
        if (error.code === 'ERR_CANCELED') {
          return Promise.resolve({ status: 499 });
        }
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        const isTrackingUrl = response.config.url?.includes('positions/tracking');
        if (isTrackingUrl) {
          trackingResponseHandler.successfulRequest();
        }
        return response;
      },
      async function (error) {
        if (error.code === 'ERR_CANCELED') {
          return Promise.resolve({ status: 499 });
        }
        if (axios.isAxiosError(error)) {
          if (handleTrackingError(error)) {
            return Promise.reject(error);
          }
          if (!navigator.onLine) {
            ctxNotify?.showNotification('error', t('error.api.err_network'));
            return Promise.resolve({ status: 497 });
          }
          if (error.response?.status === 401) {
            const isLoginUrl = error.response.config.url?.includes('login');
            if (isLoginUrl) {
              ctxNotify?.showNotification('error', t('error.api.login'));
            }
            await logout();
            return Promise.resolve({ status: 498 });
          }
          if (handleServerSideMessage(error)) {
            return Promise.reject(error);
          }
          return handleUnknownError(error);
        }
      }
    );

    return () => {
      axios.interceptors.request.clear();
      axios.interceptors.response.clear();
    };
  }, [t, ctxNotify, logout, handleServerSideMessage, handleUnknownError]);

  return children;
}
